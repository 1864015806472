import type { ReactNode } from 'react';

export interface WidgetContainerProps {
  children: ReactNode;
}

export function WidgetContainer(props: WidgetContainerProps ) {
  return <main>
    <a href='https://www.inftspaces.com/artofweb3'>
      <img src='/assets/inftspaces-logo.png' alt="logo" className="w-auto h-8 m-4" />
    </a>

    <div className="p-[30px] max-w-xl mx-auto">
    {props.children}

    </div>
  </main>

}

