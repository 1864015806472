import { HorizontalCenter } from '../../elements/HorizontalCenter';
import { settings } from '../../settings';
import { signMessage } from '../../domains/sign-message';
import { useAppState} from '../../store/store';
import invariant from 'tiny-invariant';

export function ValidatingBlock() {
  const context = useAppState();
  const {state, dispatch } = context;

  const handleOnValidateClick = async () => {
 
    invariant(state.wallet.signer, 'signer is not defined');
    invariant(state.wallet.connectedWalletAddress, 'connectedWalletAddress is not defined');
 
    const url = `${settings().site.url}/.netlify/functions/validate-access?ethAddress=${state.wallet.connectedWalletAddress}`;

    try {
      
      const token = await signMessage(state.wallet.signer);
      if(token) {
        dispatch({type: "validationCheckingRequirements"});

          const response = await fetch(url, {
            method: "GET", // GET if current is localhost
            // body: JSON.stringify( {
            //   ethAddress: '0xbFCF685360ccA98A35F79a1AbD25193143b972Fd',
            // }),
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            },
            mode: 'cors'
          });

          const data =  await response.json();
          if(data && data.requirementsMet) {
            dispatch({type: "validationRequirementsMet"});
          } else {
            dispatch({type: "validationRequirementsNotMet"});
          }


      }

    } catch (err: any) {
      dispatch({type: "validationFailure", error: {description: (err?.message || 'Something did not work out. Please try again.').toString()} });
    }

    
  }

  return (

    <HorizontalCenter>
          <button type="button" className={settings().styles.primaryButtonClassName} onClick={handleOnValidateClick}>
           Validate
          </button>
    </HorizontalCenter>
  );
}
