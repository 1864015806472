import { useAppState} from '../store/store';

import { PoweredByRojiBlock } from '../components/PoweredByRojiBlock';
import { EventbriteRedemptionBlock } from '../blocks/redemption/EventbriteRedemptionBlock';
import { WalletConnectBlock } from '../blocks/wallet-connect/WalletConnectBlock';
import { ValidatingBlock } from '../blocks/validating/ValidatingBlock';
import { WidgetContainer } from '../customization/WidgetContainer';

export default function Home() {
  const context = useAppState();
  const {state } = context;

  return (
    <WidgetContainer>

    { state.screenVisibility.walletConnectScreen && <WalletConnectBlock />}
    { state.screenVisibility.validatingScreen && <ValidatingBlock />}
    { state.screenVisibility.redemptionScreen && <EventbriteRedemptionBlock/>}

      <PoweredByRojiBlock></PoweredByRojiBlock>
    </WidgetContainer>
  );
}
