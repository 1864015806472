import type { ReactNode } from 'react';

export interface HorizontalCenterProps {
  children: ReactNode;
}

export function HorizontalCenter(props: HorizontalCenterProps ) {
  return (
    <div className="p-[10px] w-full flex flex-col  items-center  content-between ">
    {props.children}
    </div>
  );
}

