
const shared = {
  styles: {
    primaryButtonClassName: "flex-initial block py-2 px-7 rounded-[10px] shadow bg-black text-white  hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900 font-semibold",
  },
  // copy: {
  //   title: 'The Art of Web3'
  // },
  // social: {
  //   discord: 'https://discord.gg/fECW7hkukb'
  // }
}

export const production = {
  site: {
    url: 'https://nftgated.net',
  },
  mintingContract: {
  //   contractAddress: '0x120C6B31cCCeBb7aAdCFF7c5D531aD83c4eA131E',
  //   network: 'homestead',
    chainId: 1,
  //   abi: [
  //   "function balanceOf(address owner) view returns (uint256)",
  //   "function decimals() view returns (uint8)",
  //   "function symbol() view returns (string)",
  //   "event Transfer(address indexed from, address indexed to, uint amount)",
  //   "function mint(uint256 numberOfTokens,bytes calldata signature) public payable",
  //   "function availableSupply() public view returns (uint256)",
  //   "function totalSupply() public view returns (uint256)",
  //   ],
  //   maxTokensPerAddress: 5,
  //   maxSupply: 150,
  //   priceInETH: 0.11,
  //   scanLink: 'https://etherscan.io/token/0x120C6B31cCCeBb7aAdCFF7c5D531aD83c4eA131E',
  },
  wallet: {
    appName: "INFTSPACES",
    alchemy: "https://eth-mainnet.alchemyapi.io/v2/qGZES2yXt4ddmVxVS7ZFj56oBs6EWQLd"
  },
  // rojiBackend: {
  //   minterId: "aaDQfJ:UKhjxwvTKrs5vBDXdKqBw:evm:9dS1fEu8VUtXMei3tyjni5",
  //   apiKey: `LCK3hWAA43pWp8HEre243lcu6fXhtVo+:aaDQfJ`,
  //   url: "https://graphql.rojidevapi.com",
  // }, 
  // analytics: {
  //   ga4: "G-8ECSHE5WVT",
  // },
  // sentry: {
  //   dsn: "https://15842e10e1ad47efa4275225a94f2584@o1332085.ingest.sentry.io/4503886702379008",
  //   tracesSampleRate: 0.05,
  // }
  analytics: {
    ga4: null,
  },
  sentry: {
    dsn: null,
    tracesSampleRate: undefined,
  },
  eventBrite: {
    eventId: "429250258077",
  },

}

export const dev = {
  site: {
    url: 'https://nftgated.net',
  },

  mintingContract: {
  //   contractAddress: '0x7f2974Ee60cFc6C4AD6D7497053D456DBbBc5908',
  //   network: 'goerli',
  chainId: 1,
  //   abi: [
  //   "function balanceOf(address owner) view returns (uint256)",
  //   "function decimals() view returns (uint8)",
  //   "function symbol() view returns (string)",
  //   "event Transfer(address indexed from, address indexed to, uint amount)",
  //   "function mint(uint256 numberOfTokens,bytes calldata signature) public payable",
  //   "function availableSupply() public view returns (uint256)",
  //   "function totalSupply() public view returns (uint256)",
  //   ],
  //   maxTokensPerAddress: 5,
  //   maxSupply: 150,
  //   priceInETH: 0.11,
  //   scanLink: 'https://goerli.etherscan.io/token/0x7f2974Ee60cFc6C4AD6D7497053D456DBbBc5908',

  },
  wallet: {
    appName: "INFTSPACES",
    alchemy: "https://eth-mainnet.alchemyapi.io/v2/qGZES2yXt4ddmVxVS7ZFj56oBs6EWQLd"
  },
  // rojiBackend: {
  //   minterId: "aaDQfJ:UKhjxwvTKrs5vBDXdKqBw:evm:9dS1fEu8VUtXMei3tyjni4",
  //   apiKey: `LCK3hWAA43pWp8HEre243lcu6fXhtVo+:aaDQfJ`,
  //   url: "https://graphql.rojidevapi.com",
  // }, 
  analytics: {
    ga4: null,
  },
  sentry: {
    dsn: null,
    tracesSampleRate: undefined,
  },
  eventBrite: {
    eventId: "420025526657",
  },

}

export const settings = () => {
  if(process.env.NODE_ENV ==='development') {
    return {...shared, ...dev};
  } else {
    return {...shared, ...production};
  }
}