import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Main } from './Main';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-S9BRW7PQ57"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");
//ReactGA.pageview(window.location.pathname + window.location.search);

Sentry.init({
  dsn: "https://d04e24d097d94bf587dfcbc5669a6b17@o1332085.ingest.sentry.io/6611828",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
