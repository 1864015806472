
import { useAppState} from '../../store/store';
import { settings } from '../../settings';

import { connectWallet } from '../../store/wallet-logic';
import { HorizontalCenter } from '../../elements/HorizontalCenter';
// import { SupportOnDiscordBlock } from '../SupportOnDiscordBlock';

export function WalletConnectBlock() {
  const context = useAppState();
  const {state, dispatch} = context;

  const isWalletConnecting = state?.wallet.isConnecting;

  return (  
    <>

    <HorizontalCenter>
      <p className='text-center mt-2 font-semibold'>Redeem Your Free Ticket</p>
      <p className='text-justified mt-3'>Connect your wallet and sign the message to redeem your free ticket. The process is free and does not cost you any gas.</p> 
    </HorizontalCenter>


  <div className="py-8 w-full flex flex-col  items-center  content-between ">

      <button disabled={isWalletConnecting}  
              type="button" onClick={ () => connectWallet(dispatch)} 
              className={settings().styles.primaryButtonClassName}>Connect Wallet</button>
    </div>


    {/* <SupportOnDiscordBlock></SupportOnDiscordBlock> */}

    </>

  );
}