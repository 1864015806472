
import { ethers } from "ethers";
import * as Web3Token from 'web3-token';

export const signMessage = async ( signer: ethers.Signer): Promise<string> => {

  const nonce = Math.floor(Math.random() * 1000000);
  const domain = 'inftspaces.com';

  const payload = {expires_in: '1d', 
                   statement: 'Verify Identity',
                   nonce: nonce.toString(),
                   domain: domain }
  const token = await Web3Token.sign(async (msg: any) => await signer.signMessage(msg),payload as any );

  return token;
}



