// import { useAppState} from '../store/store';

import { PoweredByRojiBlock } from '../components/PoweredByRojiBlock';

export default function NotFoundRoute() {
//  const context = useAppState();
//  const {state } = context;

  return (
    <main className="bg-white subpixel-antialiased text-base">

      <PoweredByRojiBlock></PoweredByRojiBlock>
    </main>
  );
}
