import {createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';
import { useImmerReducer } from 'use-immer';
import type { Action } from './actions';
import type { AppState } from './states';
import { settings } from '../settings';

export {AppState};

const initialState: AppState = {
   wallet: {
    connectedWalletAddress: undefined,
    isConnecting: false,
    provider: undefined,
    signer: undefined,
    ethBalance: null,
    networkName: null,
    isValidNetwork: null,
  },
   screenVisibility: {
    loadingScreen: true,
    walletConnectScreen: false,
    invalidNetworkScreen: false,
    validatingScreen: false,
    redemptionScreen: false,
   },
   registration: {
    isRegistering: false,
    registrationError: undefined
   },
   couponRedemption: {
    isRedeeming: false,
    redemptionError: undefined,
   },
   emailIsValid: true,
   newRegistration: true,
   twitter: undefined,
   discord: undefined,
   isInitializedCalled: false,
   requirementsStatus: {},

   code: '',
   codeIsValidInput: true,

   allowlistState: {
    isAllowlisted: undefined,
    allowlistLevel: undefined,
   },
   couponState: {
    redeemedCouponCode: undefined,
   }
};

interface AppContext {
  state: AppState;
  dispatch: React.Dispatch<Action>
}

const store = createContext<Partial<AppContext> >({});
const { Provider } = store;

const cleanScreenVisibility = (draft: AppState) => {
  for(const key in draft.screenVisibility) {
    (draft.screenVisibility as any)[key] = false;
  }
}

export interface AppStateProviderProps {
  children: ReactNode;
}
const AppStateProvider = ( props: AppStateProviderProps) => {
  const [state, dispatch] = useImmerReducer<AppState, Action>((draft: AppState, action: Action) => {
    switch(action.type) {
      case 'appInitializedCalled':
        draft.isInitializedCalled = true;
        cleanScreenVisibility(draft);
        draft.screenVisibility.walletConnectScreen = true;
        break;

        case 'walletConnecting':
          draft.wallet.connectedWalletAddress = undefined;
          draft.wallet.connectionError = undefined;
          draft.wallet.isConnecting = true;
          break;
        case 'walletModalCancelled':
          draft.wallet.isConnecting = false;
          break;
        case 'walletConnectionFailure': 
          draft.wallet.isConnecting = false;
          draft.wallet.connectionError = action.error;
          break;
        case 'walletDisconnected':
          draft.wallet = {connectedWalletAddress: undefined,
                          connectionError: undefined, 
                          isConnecting: false, 
                          provider: undefined, 
                          signer: undefined, 
                          ethBalance: null,
                          networkName: null,
                          isValidNetwork: null,};
          draft.isInitializedCalled = false;
          cleanScreenVisibility(draft);
          draft.screenVisibility.walletConnectScreen = true;

          break;
        case 'walletConnected':
          draft.wallet.connectedWalletAddress = action.address;
          draft.wallet.signer = action.signer;
          draft.wallet.provider = action.provider;
          draft.wallet.connectionError = undefined;
          draft.wallet.isConnecting = false;
          draft.wallet.networkName = action.network.name;
          draft.wallet.isValidNetwork = action.network.chainId === settings().mintingContract.chainId;

          cleanScreenVisibility(draft);
          draft.screenVisibility.validatingScreen = true;

          // if(draft.wallet.isValidNetwork) {
          //   draft.screenVisibility.mintScreen = true;
          // } else {
          //   draft.screenVisibility.invalidNetworkScreen = true;
          // }

          break;
        case 'walletAddressChanged':
          draft.wallet.connectedWalletAddress = action.address;
          draft.wallet.connectionError = undefined;
          draft.wallet.isConnecting = false;
  
          break;

          case 'validationCheckingRequirements':
            // clear error
            break;
          case 'validationRequirementsMet':
            cleanScreenVisibility(draft);
            draft.screenVisibility.redemptionScreen = true;

  
            break;
          case 'validationRequirementsNotMet':
            // set error.
            cleanScreenVisibility(draft);
            draft.screenVisibility.validatingScreen = true;
  
            break;
      default:
        break;
    };
  }, initialState);


   useEffect(()=>{
    if(state.isInitializedCalled) {
      return;
    }
    dispatch({type: 'appInitializedCalled'});

    // const ethAddress = state.wallet.connectedWalletAddress || '';

    // if(ethAddress) {
      // (async () => {
      //   const status = await retrieveStatus(ethAddress);
      //   console.log("retrieving status logic B");

      //   if(status) {
      //     dispatch({type: 'backendStatusUpdate', status: status})
      //   }
      // })();
    // }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);




  return <Provider value={ { state, dispatch }}>{props.children}</Provider>;
};

export { store, AppStateProvider }

export const useAppState = (): AppContext => {
  const context = useContext(store);
  if(!context.state || !context.dispatch) {
    throw new Error('useAppState can only be called after initialized');
  }

//  console.log(JSON.stringify(context.state));
  return { state: context.state , dispatch: context.dispatch}
}