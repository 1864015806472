import { Action } from './actions';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from 'web3modal';
import { ethers } from "ethers";

import { settings } from '../settings';

 const rpc: {[idx: number]: string} = {};
 rpc[settings().mintingContract.chainId] = settings().wallet.alchemy;

const providerOptions = {
  /* See Provider Options Section */
  coinbasewallet: {
      package: CoinbaseWalletSDK, // Required
      options: {
          appName: settings().wallet.appName, // Required
          //  infuraId: "INFURA_ID", // Required
          rpc: settings().wallet.alchemy, // Optional if `infuraId` is provided; otherwise it's required
          chainId: settings().mintingContract.chainId, // Optional. It defaults to 1 if not provided
          darkMode: false // Optional. Use dark theme, defaults to false
      }
  },
  walletconnect: {
      package: WalletConnectProvider, // required
      options: {
          rpc: rpc
      }
  }
};


const web3Modal = new Web3Modal({
//  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

// let activeProvider: any;

export const disconnectWallet = async (dispatch: React.Dispatch<Action>) => {
  await web3Modal.clearCachedProvider();
//  tryClearSessionStorage();
  dispatch({type: 'walletDisconnected' });
};


export const connectWallet = async (dispatch: React.Dispatch<Action>) => {
  dispatch({type: 'walletConnecting'})

  await web3Modal.clearCachedProvider();
  try {
    
    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);

    const signer = provider.getSigner();
    const walletAddress = await signer.getAddress();
    const network = await provider.getNetwork();


    dispatch({type: 'walletConnected', 
              address: walletAddress,
              provider: provider, 
              signer: signer,
              network: network});



    provider.on("accountsChanged", (accounts: string[]) => {
      dispatch({type: 'walletAddressChanged', address: accounts.length > 0 ? accounts[0]: undefined});
    });

    provider.on("disconnect", (error: { code: number; message: string }) => {
      dispatch({type: 'walletDisconnected' });
    });

    provider.on("connect", async (info: { chainId: number })  => {

      const signer = provider.getSigner();
      const walletAddress = await signer.getAddress();
      const network = await provider.getNetwork();

      dispatch({type: 'walletConnected', address: walletAddress[0], provider: provider, signer: signer, network: network });
    });
    
  } catch(e) {
    if(e === "Modal closed by user") {
      dispatch({type: 'walletModalCancelled'})
      return;
    }

    const error =  { description: !!e ? (e as any).toString() : 'Could not connect' };
    dispatch( {type: 'walletConnectionFailure', error: error})

  }

}
