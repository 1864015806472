import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import App from './App';
import Home from './routes/home';
import NotFoundRoute from './routes/NotFoundRoute';
// import ReactGA from 'react-ga4';
// import {useEffect } from 'react';

import { AppStateProvider } from './store/store';

// export function AppInitializer(props: {children: ReactNode }) {
//   const context = useAppState();

//   useEffect(()=>{

//     const {state, dispatch} = context;
  
//     if(state.isInitializedCalled) {
//       return;
//     }

//     dispatch({type: 'appInitializedCalled'});

//     console.log('ONCE')
//     // do stuff here...
//   }, []);
  
//   return (<>{ props.children }</>);
// }

export function Main() {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <AppStateProvider>
  <BrowserRouter>
    <Routes>
      <Route path="/inftspaces/oct2022soho" element={<Home />} />
      <Route path="*" element={ <NotFoundRoute /> } />

    </Routes>
  </BrowserRouter>
  </AppStateProvider>
    );
}
