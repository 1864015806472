
export function PoweredByRojiBlock() {
  return (
    <div className=" max-w-md mx-auto">

    <div className="pb-14 pt-6 flex flex-col items-center ">
      <p className="pb-3 text-animeme-200 text-center content-center align-middle">
      Powered by Roji
      </p>
      <div className="">
        <a href="https://roji.io" >
          <img className="h-10 w-auto object-cover" src="/assets/MediumLogo.png" alt="The Roji logo."/>
        </a>
      </div>

    </div>
    </div>
  );
}
