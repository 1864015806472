import React from 'react';
import useEventbrite from 'react-eventbrite-popup-checkout';
import { HorizontalCenter } from '../../elements/HorizontalCenter';
import { settings } from '../../settings';


export function EventbriteRedemptionBlock() {
  const handleOrderCompleted = React.useCallback(() => {
    console.log('Order was completed successfully');
  }, []);
  const modalButtonCheckout = useEventbrite({
    eventId: settings().eventBrite.eventId,
    modal: true,
    onOrderComplete: handleOrderCompleted,
  });
  
  return (

    <HorizontalCenter>
       {modalButtonCheckout && (
          <button id={modalButtonCheckout.id} type="button" className={settings().styles.primaryButtonClassName}>
            Get Ticket
          </button>
        )}
    </HorizontalCenter>
  );
}
